import { useLayoutEffect, useState } from "react";

import Box from "../Components/Box";
import CategorySectionCreator from "../Components/CategorySectionCreator";
import Carousel from '../Components/carousel/Carousel';
import Grid from "../Components/grid/Grid";
import HomeSlider from "../Components/home-1/HomeSlider";
import NewArrivalSection from "../Components/home-1/NewArrivalSection";
import Boxes2PerRow from "../Components/homepage-sections/Boxes2PerRow";
import Category1 from "../Components/homepage-sections/Category1";
import CategoryWelcomeStyle from '../Components/homepage-sections/CategoryWelcomeStyle';
import Services from "../Components/homepage-sections/Services";
import AppLayout from "../Components/layout/AppLayout";
import { HomeProvider } from "../contexts/app/HomeContext";
import useWindowSize from '../hooks/useWindowSize';
import { useAppContext } from "../contexts/app/AppContext";

export default function Welcome(props) {

    const { isMounted, setIsMounted } = useAppContext()

    useLayoutEffect(() => {
        setIsMounted(false)
    }, [])

    const screenWidth = useWindowSize();

    function renderElement(section) {

        if (section.type == 'category') {
            return (

                <CategoryWelcomeStyle mt="-0.25rem" mb="-0.25rem" >
                    <CategorySectionCreator title={section.sectionName} />

                    {screenWidth > 768 && <Grid container containerHeight='inherit'>
                        {section.data.map((subsection) => (
                            <Grid item md={4} xs={12} key={subsection.sectionData.id}>
                                <Category1 section={subsection} sectionName={subsection.sectionData.name} />
                            </Grid>
                        ))}
                        {/*<div style={{ width: '97%' }}>
                            <Button color='white' border='solid white' className='all-btn' mb={45} mt={30}>
                                <Typography mb={'2px'}>All Collections</Typography>  <NavigateNextIcon />
                            </Button>
                        </div>*/}
                    </Grid>}

                    {screenWidth <= 768 &&
                        <Box>
                            <Carousel
                                totalSlides={section.data.length}
                                visibleSlides={1}
                                infinite={true}
                                autoPlay={false}
                                showDots={false}
                                showArrow={true}
                                spacing="0px"
                                arrowButtonColor='white'
                                leftButtonStyle={{ backgroundColor: 'transparent', left: '-22px' }}
                                rightButtonStyle={{ backgroundColor: 'transparent', right: '-22px' }}

                            >
                                {section.data.map((subsection) => (

                                    <div item md={4} xs={12} key={subsection.sectionData.id} >
                                        <Category1 section={subsection} sectionName={subsection.sectionData.name} />
                                    </div>
                                ))}

                            </Carousel>
                            {/*<Button color='white' border='solid white' style={{ float: 'right' }}> All Collections <NavigateNextIcon /> </Button>*/}
                        </Box>

                    }


                </CategoryWelcomeStyle>
            )
        } else if (section.type == 'service') {
            return <Services props={section} />
        } else if (section.type == 'category-2') {

            return <Boxes2PerRow props={section} />

        }


        return null;
    }

    return (
        <>

            <HomeProvider data={props}>

                <AppLayout seo={props.seo}>

                    <HomeSlider isMounted={isMounted} setIsMounted={setIsMounted} />

                    {/* Channel Section*/}

                    {props.homepage && isMounted && props.homepage.map((homepageSection) => (
                        renderElement(homepageSection)
                    ))}

                    {/* New Arrival Section*/}
                    {isMounted && <NewArrivalSection />}

                </AppLayout>

            </HomeProvider>
        </>
    );
}